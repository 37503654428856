import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20f5b42d = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _1723a4f0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _59980b63 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _4081946c = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _9d46fa86 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _fd2057ea = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _5259634b = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _e48ff6a0 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _783b77b2 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _cae1b4d2 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _3c71fcf3 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _0f4225fe = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _233fae7a = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _4d3ace7a = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _22d9881c = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _790a79ba = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _8352095a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _168253f6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _3b4189f9 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _709822c6 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _ff061bda = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _453975a2 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _0417316d = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _0a94ecfa = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _04ebae2c = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _0116bda9 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _3e65c40e = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _1ed6892c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0054d7ca = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _40a938a8 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _635af6c8 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _39fc9fb8 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _05a2d968 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _0c1d64c5 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _fc2eeace = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _1a079b32 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _3e78c356 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _4ab38b99 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _5649e6ba = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _72d5b3d0 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _d0e9fdc8 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _3986dd6a = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _685a56da = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _40a47f1b = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _61d2b0a6 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _c5a56e5a = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _4b746a6b = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _471fd252 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3c084f5f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _20f5b42d,
    children: [{
      path: "",
      component: _1723a4f0,
      name: "account___nl"
    }, {
      path: "details",
      component: _59980b63,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _4081946c,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _9d46fa86,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _fd2057ea,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _5259634b,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _e48ff6a0,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _783b77b2,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _cae1b4d2,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _3c71fcf3,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _0f4225fe,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _233fae7a,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _4d3ace7a,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _22d9881c,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _790a79ba,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _8352095a,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _168253f6,
    children: [{
      path: "",
      component: _3b4189f9,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _709822c6,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _ff061bda,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _453975a2,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _0417316d,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _0a94ecfa,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _04ebae2c,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _0116bda9,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _3e65c40e,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _1ed6892c,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _0054d7ca,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _40a938a8,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _635af6c8,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _39fc9fb8,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _05a2d968,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _0c1d64c5,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _fc2eeace,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _1a079b32,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _3e78c356,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _4ab38b99,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _5649e6ba,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _72d5b3d0,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _d0e9fdc8,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _3986dd6a,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _685a56da,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _40a47f1b,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _61d2b0a6,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _c5a56e5a,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _4b746a6b,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _471fd252,
    name: "index___nl"
  }, {
    path: "/*",
    component: _3c084f5f,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
